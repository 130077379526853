import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Radio, Empty, Spin, Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// INTERFACES
import {
	ControlPanelBankFloors,
	ControlPanelBankHallCallsInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";
// ICONS
import elLockedDark from "../../../assets/icons/elevatorStatus/el-locked-dark.svg";

function ControlPanelBankHallCalls({
	hasFrontDoors,
	hasRearDoors,
	bankFloors,
	frontLock,
	rearLock,
}: ControlPanelBankHallCallsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [hallCallSelected, setHallCallSelected] = useState<"U" | "D">("U");
	const [doorSelected, setDoorSelected] = useState<"F" | "R">("F");
	const [secureCalls, setSecureCalls] = useState<boolean>(false);
	const [showLoading, setShowLoading] = useState<boolean>(true);
	const [spinningFloorNo, setSpinningFloorNo] = useState<number>(0);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, layoutSelected, socketConnection } = useSelector((state: RootState) => state.monitoring);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postCommand, { isLoading: isLoadingPostCommand }] = usePostMonitoringCommandMutation();

	const [postCommandEmulator, { isLoading: isLoadingPostCommandEmulator }] = usePostEmulatorCommandMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickHallCall = async (floor: number) => {
		const token = await GETJwtToken();
		if (socketConnection !== "EMULATOR") {
			const BODY = {
				token,
				body: {
					cmd_adapter: layoutSelected && layoutSelected.typeChannel && layoutSelected.typeChannel === "ADAPTER",
					cmd_off: false,
					lift_id: layoutSelected.lifts[0].id,
					short_msg: `${secureCalls ? "S" : ""}E${hallCallSelected}${doorSelected === "R" ? "R" : ""}HC${floor}`,
					type: "CONTROL",
				},
				serverId: layoutSelected.idServer,
			};
			postCommand(BODY);
		} else {
			const BODY = {
				token,
				body: {
					type: "HALLCALL",
					lift_id: liftSelected.id,
					floor,
					bank_id: layoutSelected.idBank,
					front_rear: doorSelected === "F" ? "front" : "rear",
					upDown: hallCallSelected === "U" ? "up" : "down",
					upDownAssigned: hallCallSelected === "U" ? "upAssigned" : "downAssigned",
				},
				serverId: layoutSelected.idServer,
			};
			postCommandEmulator(BODY);
		}
	};

	const onChangeSecureCalls = (checked: boolean) => {
		setSecureCalls(checked);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		setShowLoading(true);
		setTimeout(() => {
			setShowLoading(false);
		}, 500);
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className={`montoringView__controlPanel__${theme}`}
			style={{ minHeight: "400px", height: "auto", padding: "20px" }}
		>
			<Spin spinning={showLoading}>
				<div>
					{!showLoading && (
						<>
							{(hasFrontDoors || hasRearDoors) && (
								<>
									{/** ACTIONS */}
									<div className={` drawer__box__control__panel__${theme} `}>
										<div className='generalStyles__spaceBetween'>
											<div className='generalStyles__flex'>
												<Radio.Group
													value={doorSelected}
													onChange={(e) => {
														setDoorSelected(e.target.value);
													}}
												>
													<Radio value='F'>{t("configuration.configurationFiles.frontDoors")}</Radio>
													<Radio value='R'>{t("configuration.configurationFiles.rearDoors")}</Radio>
												</Radio.Group>
											</div>
											{layoutSelected &&
												layoutSelected.idOrganization &&
												layoutSelected.idOrganization === "88bed3b9-28b1-452f-a2bb-f4a29646bbf8" && (
													<div>
														{secureCalls && (
															<FontAwesomeIcon
																icon={faLock}
																fontSize={18}
																className='monitoring__modalSettings__icon generalStyles__mrFix'
															/>
														)}
														<span className='generalStyles__mrFix' style={{ marginTop: "5px" }}>
															{t("general.secureCalls")}
														</span>
														<Switch checked={secureCalls} onChange={onChangeSecureCalls} />
													</div>
												)}
										</div>
									</div>
									{/** ACTIONS */}
									<div className={` drawer__box__control__panel__${theme} mt-3`}>
										<div className='generalStyles__flex'>
											<div className='generalStyles__width100'>
												<div className='generalStyles__flex'>
													<Radio.Group
														value={hallCallSelected}
														onChange={(e) => {
															setHallCallSelected(e.target.value);
														}}
													>
														<Radio value='U'>UP</Radio>
														<Radio value='D'>DOWN</Radio>
													</Radio.Group>
												</div>
											</div>
										</div>
									</div>
								</>
							)}
							{/** PANEL / CIRCLE BUTTONS */}
							<div className={`drawer__box__control__panel__${theme} mt-3`}>
								<div className='monitoringView__controlPanelPanel'>
									{hasFrontDoors || hasRearDoors ? (
										<>
											{bankFloors.map((floor: ControlPanelBankFloors) => (
												<div key={floor.id}>
													<Button
														className='monitoring__modalSettings__button__1'
														shape='circle'
														onClick={() => {
															onClickHallCall(floor.floor_no);
															setSpinningFloorNo(floor.floor_no);
														}}
														disabled={
															(doorSelected === "F" && !floor.front_door) ||
															(doorSelected === "R" && !floor.rear_door) ||
															(frontLock.includes(floor.floor_no) && doorSelected === "F") ||
															(rearLock.includes(floor.floor_no) && doorSelected === "R")
														}
													>
														{(frontLock.includes(floor.floor_no) && doorSelected === "F") ||
														(rearLock.includes(floor.floor_no) && doorSelected === "R") ? (
															<img src={elLockedDark} alt='' />
														) : (
															<>
																{(isLoadingPostCommand || isLoadingPostCommandEmulator) &&
																spinningFloorNo === floor.floor_no ? (
																	<Spin
																		spinning={
																			(isLoadingPostCommand || isLoadingPostCommandEmulator) &&
																			spinningFloorNo === floor.floor_no
																		}
																	/>
																) : (
																	<span style={{ marginTop: "3px" }}>
																		{floor && floor.name && floor.name !== ""
																			? floor.name.substring(0, 3).toUpperCase()
																			: floor.floor_no}
																	</span>
																)}
															</>
														)}
													</Button>{" "}
												</div>
											))}
										</>
									) : (
										<Empty description={<span>{t("general.bankHasNoFrontRearDoors")}</span>} />
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</Spin>
		</div>
	);
}

export default ControlPanelBankHallCalls;
